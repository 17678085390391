<template>
 <div id="chart"  class="grow flex-wrap">
        <apexchart type="rangeBar" :height=height :width=width :options="Timeline.chartOptions" :series="Timeline.series"></apexchart>
      </div>
</template>

<script>
export default {
    name:"BasicTimeline",
    props: ["data", "height", "width"],
    data(){
        return{
            Timeline:{
                 series: [
            {
              data:this.data
            }
          ],
          chartOptions: {
            chart: {
              height: 350,
              type: 'rangeBar'
            },
            plotOptions: {
              bar: {
                horizontal: true
              }
            },
            xaxis: {
              type: 'datetime'
            }
          },
          
            }
        }
    }
}
</script>

<style>

</style>