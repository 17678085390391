<template>
  <div id="chart" class="grow flex-wrap" >
        <apexchart type="bar" :height=height :width=width :options="bar.chartOptions" :series="bar.series"></apexchart>
      </div>
</template>

<script>
export default {
    name:"ColumnWithMarkers",
     props: ["data", "height", "width"],
    data(){
        return{
            bar:{
               series: [
            {
              name: 'Actual',
              data: this.data,
            }
          ],
          chartOptions: {
            chart: {
              height: 350,
              type: 'bar'
            },
            plotOptions: {
              bar: {
                columnWidth: '60%'
              }
            },
            colors: ['#00E396'],
            dataLabels: {
              enabled: false
            },
            legend: {
              show: true,
              showForSingleSeries: true,
              customLegendItems: ['Actual', 'Expected'],
              markers: {
                fillColors: ['#00E396', '#775DD0']
              }
            }
          },
          
          
        
            }
        }
    }

}
</script>

<style>

</style>