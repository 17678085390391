<template>
  <div id="chart" class="grow flex-wrap">
        <apexchart type="area" :height=height :width=width :options="area.chartOptions" :series="area.series"></apexchart>
      </div>
    
</template>

<script>
export default {
    name:"BasicArea",
    props: ["data", "height", "width"],
    data(){
         return{
             area:{
                   series: [{
            name: "",
            data: this.data.yAxis
          }],
          chartOptions: {
            chart: {
              type: 'area',
              height: 350,
              zoom: {
                enabled: false
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'straight'
            },
            
           /*  title: {
              text: 'Fundamental Analysis of Stocks',
              align: 'left'
            } P,*/
            /* subtitle: {
              text: 'Price Movements',
              align: 'left'
            } ,*/
            labels: this.data.xAxis,
            xaxis: {
              type: 'string',
            },
            yaxis: {
              opposite: true
            },
            legend: {
              horizontalAlign: 'left'
            }     
          
        },

             }

         }
     }

}
</script>

<style>

</style>