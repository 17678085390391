<template>
    <div id="chart" class="grow flex-wrap">
              <apexchart
                type="radialBar"
                :height=height
      :width=width
                :options="radialBar.chartOptions"
                :series="radialBar.series"
              ></apexchart>
            </div>
</template>

<script>
export default {
    name:'RadialRadar',
      props: ["data", "height", "width"],
    data(){
        return{
                 radialBar: {
        series: this.data.yAxis,
        chartOptions: {
          chart: {
            height: 350,
            type: "radialBar",
              toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            }
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          }
        },
        autoSelected: 'zoom' 
      },
          },
          plotOptions: {
            radialBar: {
              dataLabels: {
                name: {
                  fontSize: "22px",
                },
                value: {
                  fontSize: "16px",
                },
                total: {
                  show: true,
                  label: "Total",
                  formatter: function (w) {
                    // By default this function returns the average of all series. The below is just an example to show the use of custom formatter function
                    return 249;
                  },
                },
              },
            },
          },
          labels: this.data.xAxis,
        },
      },
        }
    }

}
</script>

<style>

</style>