<template>
   <div id="chart" class="grow flex-wrap" >
   <apexchart type="line" :height=height :width=width :options="line.chartOptions" :series="line.series"></apexchart>
  </div>
</template>

<script>
export default {
  name:"LineDatatable",
   props: ["data", "height", "width"],
        data() {
            return{
            line: {
          series:this.data.yAxis,
          chartOptions: {
            chart: {
              height: 350,
              type: 'line',
              dropShadow: {
                enabled: true,
                color: '#000',
                top: 18,
                left: 7,
                blur: 10,
                opacity: 0.2
              },
              toolbar: {
                show: false
              }
            },
            colors: ['#77B6EA', '#545454'],
            dataLabels: {
              enabled: true,
            },
            stroke: {
              curve: 'smooth'
            },
         /*    title: {
              text: 'Average High & Low Temperature',
              align: 'left'
            }, */
            grid: {
              borderColor: '#e7e7e7',
              row: {
                colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.5
              },
            },
            markers: {
              size: 1
            },
            xaxis: {
              categories: this.data.xAxis,
            /*   title: {
                text: 'Month'
              } */
            },
            yaxis: {
            /*   title: {
                text: 'Temperature'
              }, */
              min: 5,
              max: 40
            },
            legend: {
              position: 'top',
              horizontalAlign: 'right',
              floating: true,
              offsetY: -25,
              offsetX: -5
            }
          },
            }
          
        
}}
    



}
</script>

<style>

</style>