<template>
   <div id="chart">
    <apexchart type="treemap"  :height=height
      :width=width :options="treemap.chartOptions" :series="treemap.series"></apexchart>
    </div>
</template>

<script>
import { generateColorForCharts} from '../../Utils/ChartUtilities';
export default {
    name:"DistributedTreemap",
     props:['data','height','width','color'],
    data(){
        return{
            treemap:{
                series: [
            {
              data:this.data
            }
          ],
          chartOptions: {
            legend: {
              show: false
            },
            chart: {
              height: 350,
              type: 'treemap'
            },
           /*  title: {
              text: 'Distibuted Treemap (different color for each cell)',
              align: 'center'
            }, */
           colors: generateColorForCharts(Object.keys(this.data).length),
            plotOptions: {
              treemap: {
                distributed: true,
                enableShades: false
              }
            }
          },
          
          

            }
            

        }
    }

}
</script>

<style>

</style>