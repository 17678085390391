<template>
   <div id="chart"  class="grow flex-wrap">
        <apexchart type="polarArea" :height=height :width=width :options="basic.chartOptions" :series="basic.series"></apexchart>
      </div>
</template>

<script>
export default {
    name:"BasicPolarArea",
    props: ["data", "height", "width"],
    data(){
        return{
            basic:{
                 series: this.data.yAxis,
          chartOptions: {
            chart: {
              type: 'polarArea',
            },
            labels: this.data.xAxis,
            stroke: {
              colors: ['#fff']
            },
            fill: {
              opacity: 0.8
            },
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          },
          
            
            }
        }
    }

}
</script>

<style>

</style>