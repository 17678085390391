<template>
  <div id="chart" class="grow flex-wrap" >
        <apexchart type="bar" :height=height :width=width :options="bar.chartOptions" :series="bar.series"></apexchart>
      </div>
</template>

<script>
export default {
    name:"ColumnDatalabel",
     props: ["data", "height", "width"],
    data(){
        return{
            bar:{
                series: [{
            name: 'Cash Flow',
            data:this.data.yAxis,
          }],
          chartOptions: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                colors: {
                  ranges: [{
                    from: -100,
                    to: -46,
                    color: '#F15B46'
                  }, {
                    from: -45,
                    to: 0,
                    color: '#FEB019'
                  }]
                },
                columnWidth: '80%',
              }
            },
            dataLabels: {
              enabled: false,
            },
            yaxis: {
            /*   title: {
                text: 'Growth',
              }, */
              labels: {
                formatter: function (y) {
                  return y + "%";
                }
              }
            },
            xaxis: {
              type: 'string',
              categories: this.data.xAxis,
              labels: {
                rotate: -90
              }
            }
          },
            }
        }
    }

}
</script>

<style>

</style>