<template>
  <div id="chart" class="grow flex-wrap" >
        <apexchart type="bar" :height=height :width=width :options="bar.chartOptions" :series="bar.series"></apexchart>
      </div>
    
</template>

<script>
export default {
    name:"StackedColumn",
     props: ["data", "height", "width"],
    data(){
        return{
            bar:{
                series:this.data.yAxis,

          chartOptions: {
            chart: {
              type: 'bar',
              height: 350,
              stacked: true,
              toolbar: {
                show: true
              },
              zoom: {
                enabled: true
              }
            },
            responsive: [{
              breakpoint: 480,
              options: {
                legend: {
                  position: 'bottom',
                  offsetX: -10,
                  offsetY: 0
                }
              }
            }],
            plotOptions: {
              bar: {
                horizontal: false,
                borderRadius: 10
              },
            },
            xaxis: {
              type: 'string',
              categories:this.data.xAxis,
            },
            legend: {
              position: 'right',
              offsetY: 40
            },
            fill: {
              opacity: 1
            }
          },
           
        }
        }
    }

}
</script>

<style>

</style>