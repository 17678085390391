<template>
  <div id="chart" class="grow flex-wrap" >
   <apexchart type="line" :height=height :width=width :options="line.chartOptions" :series="line.series"></apexchart>
  </div>
</template>

<script>
export default {
    name:"DashedLine",
     props: ["data", "height", "width"],
data() {
 return{
      line: {
      series:this.data.yAxis,
           chartOptions: {
            chart: {
              height: 350,
              type: 'line',
              zoom: {
                enabled: false
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              width: this.LinesWidth(5),
              curve: 'straight',
              dashArray: this.LinesWidth(5)
            },
            legend: {
              tooltipHoverFormatter: function(val, opts) {
                return val + ' - ' + opts.w.globals.series[opts.seriesIndex][opts.dataPointIndex] + ''
              }
            },
            markers: {
              size: 0,
              hover: {
                sizeOffset: 6
              }
            },
            xaxis: {
              categories: this.data.xAxis,
            },
            tooltip: {
              y: [
                {
                  title: {
                    formatter: function (val) {
                      return val + " (mins)"
                    }
                  }
                },
                {
                  title: {
                    formatter: function (val) {
                      return val + " per session"
                    }
                  }
                },
                {
                  title: {
                    formatter: function (val) {
                      return val;
                    }
                  }
                }
              ]
            },
            grid: {
              borderColor: '#f1f1f1',
            }
          },
      }
 }   
},
methods:{
    LinesWidth:function(value){
        let widths=[];
        for(let i = 0 ; i < this.data.yAxis.length;i++)
        {
            widths.push(value);
        }
        return widths;
    }
    }
}

</script>

<style>

</style>