<template>
  <div id="chart" class="grow flex-wrap">
    <apexchart
      type="line"
       :height=height
      :width=width
      :options="line.chartOptions"
      :series="line.series"
    ></apexchart>
  </div>
</template>

<script>
export default {
  name: "BasicLine",
    props:['data','height','width'],
  data() {
    return {
      line: {
        series: [
          {
            name: "Desktops",
            data: this.data.yAxis,
          },
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: "line",
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: this.data.xAxis,
          },
        },
      },
    };
  },
};
</script>

<style>
</style>