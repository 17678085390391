

export function generateColorForCharts(size){
    let allCollors=[];
    const maxVal = 0xFFFFFF;
    for(let i = 0 ; i < size ; i++)
    {
        let SameColor = true;
        while(SameColor === true)
        {
            let randomNumber = Math.random() * maxVal; 
        randomNumber = Math.floor(randomNumber);
        randomNumber = randomNumber.toString(16);
        let randColor = randomNumber.padStart(6, 0);  
        let newColor = '#'+randColor.toUpperCase();
        
        if(allCollors.includes(newColor))
        {
            SameColor = true;
        }
        else
        {
            allCollors.push(newColor);
            SameColor = false;
        }

       
        }
        

    }
    return allCollors;

}
//console.log(generateColorForCharts(10));

