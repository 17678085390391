<template>
  <div id="chart" class="grow flex-wrap" >
        <apexchart type="bar" :height=height :width=width :options="bar.chartOptions" :series="bar.series"></apexchart>
      </div>
    
</template>

<script>
export default {
    name:"ColumnBasic",
     props: ["data", "height", "width"],
    data(){
        return{
            bar:{
            series:this.data.yAxis,

          chartOptions: {
            chart: {
              type: 'bar',
              height: 350
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories:this.data.xAxis,
            },
            yaxis: {
              /* title: {
                text: '$ (thousands)'
              } */
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return "$ " + val + " thousands"
                }
              }
            }
          }
        }}
    }

}
</script>

<style>

</style>