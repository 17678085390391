<template>
 <div id="chart"  class="grow flex-wrap">
        <apexchart type="rangeBar" :height=height :width=width :options="Timeline.chartOptions" :series="Timeline.series"></apexchart>
      </div>
</template>

<script>
export default {
    
    name:"MultiseriesTimeline",
    props: ["data", "height", "width"],
    data(){
        return{
            Timeline:{
                 series:this.data,
          chartOptions: {
            chart: {
              height: 350,
              type: 'rangeBar'
            },
            plotOptions: {
              bar: {
                horizontal: true
              }
            },
            dataLabels: {
              enabled: true,
              formatter: function(val) {
                const moment= require('moment')   
                var a = moment(val[0])
                var b = moment(val[1])
                var diff = b.diff(a, 'days')
                return diff + (diff > 1 ? ' days' : ' day')
              }
            },
            fill: {
              type: 'gradient',
              gradient: {
                shade: 'light',
                type: 'vertical',
                shadeIntensity: 0.25,
                gradientToColors: undefined,
                inverseColors: true,
                opacityFrom: 1,
                opacityTo: 1,
                stops: [50, 0, 100, 100]
              }
            },
            xaxis: {
              type: 'datetime'
            },
            legend: {
              position: 'top'
            }
          },
          
          
            }
            
    }
    
    }
}
</script>

<style>

</style>