<template>
  
  <div id="chart" class="grow flex-wrap">
    <apexchart
      type="pie"
      :height=height
      :width=width
      :options="pie.chartOptions"
      :series="pie.series"
    ></apexchart>
  </div>
</template>

<script>
import { generateColorForCharts} from '../../Utils/ChartUtilities';
export default {
    name:'Pie',
    props:['data','height','width'],
  data() {
    return {
      pie: {
        series: this.data.yAxis,
        chartOptions: {
          chart: {
            type: "pie",
                
            
              toolbar: {
        show: true,
        offsetX: 0,
        offsetY: 0,
        tools: {
          download: true,
          selection: true,
          zoom: true,
          zoomin: true,
          zoomout: true,
          pan: true,
          reset: true | '<img src="/static/icons/reset.png" width="20">',
          customIcons: []
        },
        export: {
          csv: {
            filename: undefined,
            columnDelimiter: ',',
            headerCategory: 'category',
            headerValue: 'value',
            dateFormatter(timestamp) {
              return new Date(timestamp).toDateString()
            }
          },
          svg: {
            filename: undefined,
          },
          png: {
            filename: undefined,
          }
        },
        autoSelected: 'zoom' 
      },
           
          },
          labels: this.data.xAxis,
          colors: generateColorForCharts(this.data.xAxis.length),
          
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
        },
        
      },
    };
  },
};
</script>

<style>
#chart{
  height: 400px;
}
</style>