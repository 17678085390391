<template>
   <div id="chart" class="grow flex-wrap">
              <apexchart
                type="radar"
                :height=height
      :width=width
                :options="radar.chartOptions"
                :series="radar.series"
              ></apexchart>
            </div>
</template>

<script>
export default {
    name:'radar',
      props: ["data", "height", "width"],
    data(){
        return {
                radar: {
        series: [
          {
            name: "Series 1",
            data: this.data.yAxis,
          },
        ],
        chartOptions: {
          chart: {
            height: 350,
            type: "radar",
          },
         /*  title: {
            text: "Basic Radar Chart",
          }, */
          xaxis: {
            categories:this.data.xAxis,
          },
        },
      }
        }
    }

}
</script>

<style>

</style>